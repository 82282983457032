/**
 * 会员管理API
 */

import request from '@/utils/request'
import md5 from 'js-md5'

/**
 * 获取会员列表
 * @param params
 */
export function getMemberList(params) {
  return request({
    url: 'admin/members',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加会员
 * @param params
 */
export function addMember(params) {
  params = JSON.parse(JSON.stringify(params))
  params.password = md5(params.password)
  return request({
    url: 'admin/members',
    method: 'post',
    data: params
  })
}

/**
 * 获取会员详情
 * @param id
 */
export function getMemberDetail(id) {
  return request({
    url: `admin/members/${id}`,
    method: 'get'
  })
}

/**
 * 修改会员
 * @param id
 * @param params
 */
export function editMember(id, params) {
  if (params.password) {
    params.password = md5(params.password)
  }
  return request({
    url: `admin/members/${id}`,
    method: 'put',
    data: params,
    log: {
      type: '2',
      name: '修改客户'
    }
  })
}

/**
 * 删除会员
 * @param id
 */
export function deleteMember(id) {
  return request({
    url: `admin/members/${id}`,
    method: 'delete'
  })
}

/**
 * 获取会员回收站列表
 * @param params
 */
export function getRecycleMemberList(params) {
  return request({
    url: 'admin/members',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 恢复会员
 * @param id
 */
export function recycleMember(id) {
  return request({
    url: `admin/members/${id}`,
    method: 'post'
  })
}

/**
 * 获取会员收货地址列表
 * @param member_id
 * @param params
 */
export function getMemberAddress(member_id, params) {
  return request({
    url: `admin/members/addresses/${member_id}`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取会员咨询列表
 * @param params
 */
export function getMemberAsks(params) {
  return request({
    url: 'admin/members/asks',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除会员咨询
 * @param ask_id
 */
export function deleteMemberAsk(ask_id) {
  return request({
    url: `admin/members/asks/${ask_id}`,
    method: 'delete'
  })
}

/**
 * 删除会员咨询 all
 * @param ask_id
 */
export function deleteMemberAskAll(ask_id) {
  return request({
    url: `admin/members/asks/${ask_id}`,
    method: 'delete'
  })
}

/**
 * 获取会员咨询详情
 * @param ask_id
 */
export function getMemberAsksDetail(ask_id) {
  return request({
    url: `admin/members/asks/${ask_id}`,
    method: 'get'
  })
}

/**
 * 获取会员咨询详情
 * @param ask_id
 */
export function replyAsks(ask_id, data) {
  return request({
    url: `admin/members/asks/replay/${ask_id}`,
    method: 'post',
    data
  })
}

/**
 * 获取会员咨询回复列表
 * @param params
 */
export function getMemberAsksReply(params) {
  return request({
    url: 'admin/members/reply',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除会员咨询回复
 * @param id
 */
export function deleteMemberAskReply(id) {
  return request({
    url: `admin/members/reply/${id}`,
    method: 'delete'
  })
}

/**
 * 修改会员消费积分
 * @param member_id
 * @param point
 */
export function editMemberConsumPoint(member_id, point) {
  return request({
    url: `admin/members/point/${member_id}`,
    method: 'put',
    data: { point }
  })
}

/**
 * 获取会员积分列表
 * @param params
 */
export function getMemberPointList(params) {
  return request({
    url: `admin/members/point/${params.member_id}`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 审核咨询
 * @param params
 * @returns {*}
 */
export function batchAuditAsk(param) {
  return request({
    url: `admin/members/asks/batch/audit`,
    method: 'POST',
    data: param,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * 审核咨询回复
 * @param params
 * @returns {*}
 */
export function batchAuditReply(param) {
  return request({
    url: `admin/members/reply/batch/audit`,
    method: 'POST',
    data: param,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * 获取会员评论列表
 * @param params
 */
export function getMemberComments(params) {
  return request({
    url: 'admin/members/comments',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除会员评论
 * @param comment_id
 */
export function deleteMemberComments(comment_id) {
  return request({
    url: `admin/members/comments/${comment_id}`,
    method: 'delete'
  })
}

/**
 * 审核评论
 * @param comment_id
 */
export function examineMemberComments(comment_id, params) {
  return request({
    url: `admin/members/comments/${params}?audit_status=${comment_id}`,
    method: 'post'
  })
}

/**
 * 审核商品评论
 * @param params
 * @returns {*}
 */
export function batchAuditComment(param) {
  return request({
    url: `admin/members/comments/batch/audit`,
    method: 'POST',
    data: param,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * 获取会员评论详情
 * @param comment_id
 */
export function getMemberCommentDetail(comment_id) {
  return request({
    url: `admin/members/comments/${comment_id}`,
    method: 'get'
  })
}

/**
 * 获取客户列表
 * @param params
 */
export function getPageList(params) {
  return request({
    url: 'admin/members/page',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除客户
 * @param id
 */
export function delete_members(id) {
  return request({
    url: `admin/members/${id}`,
    method: 'DELETE',
    loading: false,
    log: {
      type: '2',
      name: '禁用客户'
    }
  })
}

/**
 * 恢复客户
 * @param id
 */
export function post_members(id) {
  return request({
    url: `admin/members/${id}`,
    method: 'post',
    loading: false,
    log: {
      type: '2',
      name: '启用客户'
    }
  })
}

/**
 * 修改信息
 * @param id
 */
export function shopAdminMsg(id, params) {
  return request({
    url: `/admin/shops/shopAdmin/${id}`,
    method: 'put',
    loading: false,
    params,
    log: {
      type: '101',
      name: '修改商户'
    }
  })
}

/**
 * 查询用户出行人，用于列表查询
 * @param id
 */
export function getTravelerlist(params) {
  return request({
    url: `/admin/active/traveler/list`,
    method: 'post',
    loading: false,
    params
  })
}
/**
 * 用户紧急联系人，用于列表查询
 * @param id
 */
export function getEmergencierlist(params) {
  return request({
    url: `/admin/active/emergencier/list`,
    method: 'post',
    loading: false,
    params
  })
}
/**
 * 店铺审核
 * @param params
 */
export function shopsAudit(params) {
  return request({
    url: `/admin/shops/audit`,
    method: 'PUT',
    loading: false,
    params
  })
}

/**
 * 查询一个商户的的分类佣金
 * @param shop_id
 */
export function getShopCategory(shop_id, params) {
  return request({
    url: `/admin/shops/getShopCategory`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 修改商户佣金设置
 * @param params
 */
export function editShopsCommission(params) {
  return request({
    url: `/admin/shops/commission`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params,
    log: {
      type: '1600852',
      name: '商户佣金设置'
    }
  })
}
/**
 * 修改客户核销权限
 * member_verify_permissions 用户核销权限 0没有 1有
 */
export function putMemberVerify(member_id, params) {
  return request({
    url: `/admin/members/putMemberVerify/${member_id}`,
    method: 'put',
    loading: false,
    params: {member_verify_permissions: params}
  })
}
