<template>
  <div>
    <!-- 客户列表 -->
    <en-table-layout tips :tableData="tableData.data" @selection-change="handleSelectionChange" :loading="loading"
      ref="multipleTable" :rowKey="(row) => { return row.member_id }">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--绑定时间-->
          <div class="conditions">
            <div>绑定时间：</div>

            <!-- type="date" -->
            <el-date-picker v-model="advancedForm.times" :editable="false" value-format="timestamp" type="daterange"
              range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
              :picker-options="{ disabledDate: MixinDisabledDate }"></el-date-picker>
            <!-- :default-time="['00:00:00', '23:59:59']" -->

          </div>
          <!-- 请输入客户手机号-->
          <div class="conditions">
            <el-input class="ipt-default" style="width: 200px" v-model="advancedForm.mobile" placeholder="请输入客户手机号"
              clearable></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格上部按钮 -->
      <div slot="toolbar">
        <el-button class="btn-default" @click="handleDialog(1)">群发短信</el-button>
        <el-button class="btn-default" @click="handleDialog(2)">群发站内信</el-button>
        <el-button class="btn-default" @click="handleDialog(3)">{{ btnAllFlag ? '取消全选' : '选择全部' }}</el-button>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column type="selection" :selectable="() => { return !btnAllFlag }" :reserve-selection="true"
          width="55"></el-table-column>
        <el-table-column prop="union_id" label="openID" width="180" />
        <el-table-column prop="mobile" label="客户手机号" width="180" />
        <el-table-column prop="nick_name" label="微信昵称" width="180">
          <template slot-scope="scope"><span v-html="scope.row.nick_name"></span></template>
        </el-table-column>
        <el-table-column label="绑定时间" width="180">
          <template slot-scope="scope">{{ scope.row.bind_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column label="累计消费金额" width="180">
          <template slot-scope="scope">{{ scope.row.cumulative_money ? scope.row.cumulative_money : 0 |
      unitPrice("￥") }}</template>
        </el-table-column>

        <el-table-column prop="order_total" label="订单数量" width="180" />

        <el-table-column prop="sex" label="性别" width="180">
          <template slot-scope="scope">{{ scope.row.sex === 1 ? '男' : '女' }}</template>
        </el-table-column>
        <el-table-column prop="sort" label="核销权限" width="120">
          <template slot-scope="scope">
            <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.member_verify_permissions"
              @change="setActivityDisplayable(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleScaleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="text" @click="handleDeleteAll(scope.row)">禁用</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size" layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--群发短信 dialog-->
    <el-dialog title="群发短信" :visible.sync="dialogMessageVisible" width="650px" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form :model="MessageVisibleForm" :rules="MessageVisibleRules" ref="MessageVisibleForm" label-width="130px">
        <!--发送对象-->
        <el-form-item label="发送对象" prop="message_people">
          <span v-if="!btnAllFlag">
            共
            <span>{{ num }}</span>个客户
          </span>
          <span v-else>
            <span>所有客户</span>
          </span>
        </el-form-item>
        <!--短信内容-->
        <el-form-item label="短信模板选择" prop="modelid">
          <el-select v-model="MessageVisibleForm.modelid" placeholder="请选择短信模板">
            <el-option v-for="item in messageDiytemplate" :key="item.id" :label="item.tpl_name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogMessageVisible = false">取 消</el-button>
        <el-button class="btn-default-dialog-yes" @click="submitMessageVisibleForm('MessageVisibleForm')">确
          定</el-button>
      </div>
    </el-dialog>
    <!--群发站内信 dialog-->
    <el-dialog title="群发站内信" :visible.sync="dialogZhanneiMessageVisible" width="650px" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form :model="ZhanneiMessageVisibleForm" :rules="ZhanneiMessageVisibleRules" ref="ZhanneiMessageVisibleForm"
        label-width="100px">
        <!--发送对象-->
        <el-form-item label="发送对象" prop="message_people">
          <span v-if="!btnAllFlag">
            共<span style="color: darkred;">{{ num }}</span>个客户
          </span>
          <span v-else>
            <span>所有客户</span>
          </span>
        </el-form-item>
        <!--标题-->
        <el-form-item label="标题" prop="title">
          <el-input v-model="ZhanneiMessageVisibleForm.title" :maxlength="20"></el-input>
        </el-form-item>
        <!--短信内容-->
        <el-form-item label="短信内容" prop="message">
          <el-input type="textarea" v-model="ZhanneiMessageVisibleForm.message" :maxlength="100"></el-input>
          <div style="font-size: 96%; color: #999">
            站内信标题不能超过<span style="color: darkred;">20</span>个字，内容不能超过<span style="color: darkred;">100</span>个字。
          </div>
        </el-form-item>
      </el-form>
      <!-- / -->
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogZhanneiMessageVisible = false">取 消</el-button>
        <el-button class="btn-default-dialog-yes" @click="submitMessageVisibleForm('ZhanneiMessageVisibleForm')">确
          定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Member from "@/api/member";
// import { RegExp } from "bms-common/ui-utils";
import * as API_Sms from "@/api/sms";
export default {
  name: "memberList",
  // watch: {
  //   "addMemberForm.region"(next, prev) {
  //     if (next && next.length) {
  //       this.$refs.addMemberForm.clearValidate("region");
  //     }
  //   }
  // },
  data () {
    return {
      /** 列表loading状态 */
      loading: false,

      // 地区id数组
      defaultRegion: null,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
        disabled: 0,
      },

      /** 列表数据 */
      tableData: "",

      /** 高级搜索数据 */
      advancedForm: {
        keyword: "", // 根据关键字搜索
        times: "",
      },

      /** 群发短信 */
      dialogMessageVisible: false,
      MessageVisibleForm: {
        type: 2,
        sendall: "",
        modelid: "",
      },
      MessageVisibleRules: {
        modelid: [
          { required: true, message: "请选择短信模板", trigger: "change" },
        ],
      },
      /** 群发站内信 */
      dialogZhanneiMessageVisible: false,
      ZhanneiMessageVisibleForm: {
        type: 2,
        sendall: "",
      },
      ZhanneiMessageVisibleRules: {
        title: [
          { required: true, message: "请填写站内信标题", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        message: [
          { required: true, message: "请填写内容", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
      },

      ids_list: [], // 复选框数据
      // 记录选择的数据列数
      num: 0,
      // 记录是否点击的选择全部
      btnAllFlag: false,
      // messageDiytemplate  短信模板
      messageDiytemplate: [],
    };
  },

  mounted () {
    this.GET_MemberList();
    // 首先获取短信模板
    API_Sms.getMessageDiytemplate().then((res) => {
      console.log(res, "获取短信模板");
      this.messageDiytemplate = res;
    });
  },
  beforeRouteUpdate (to, from, next) {
    this.GET_MemberList();
    next();
  },
  methods: {
    /** 当选择项发生变化 */
    handleSelectionChange (val) {
      this.ids_list = val.map((item) => item.member_id);
      this.num = this.ids_list.length;
    },
    /** 群发 */
    handleDialog (val) {
      if (val !== 3 && this.ids_list.length === 0) {
        this.$message.error("请勾选客户！");
        return;
      }
      // val  1 群发短信  2群发站内信 3 选择全部
      if (val === 1) {
        this.dialogMessageVisible = true;
      } else if (val === 2) {
        this.dialogZhanneiMessageVisible = true;
      } else {
        var _this = this;
        this.btnAllFlag = !this.btnAllFlag;
        // 选中数据回显
        _this.$nextTick(() => {
          _this.$refs.multipleTable.selectCheckAll(_this.btnAllFlag);
        });
      }
    },
    /** 查看 */
    handleScaleEdit (row) {
      this.$router.push({
        path: `/customer/customer-detail/${row.member_id}`,
      });
    },
    /** 禁用 */
    handleDeleteAll (row) {
      this.$confirm("确认要禁用该客户吗？", "提示", {
        type: "warning",
        cancelButtonClass: "btn-default-dialog-no",
        confirmButtonClass: "btn-default-dialog-yes",
      })
        .then(() => {
          API_Member.delete_members(row.member_id)
            .then((res) => {
              this.$message.success("操作成功！");
              this.GET_MemberList();
            })
            .catch(() => { });
        })
        .catch(() => { });
    },
    async setActivityDisplayable (row) {
      await API_Member.putMemberVerify(row.member_id, row.member_verify_permissions ? 1 : 0)
      this.GET_MemberList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_MemberList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_MemberList();
    },

    /** 搜索事件触发 */
    searchEvent () {
      // Object.keys(this.advancedForm).forEach(key => delete this.params[key]);
      this.params.page_no = 1;
      this.params = {
        ...this.params,
        page_no: 1,
        ...this.advancedForm,
      };
      delete this.params.start_time;
      delete this.params.end_time;
      if (this.advancedForm.times) {
        this.params.start_time = this.advancedForm.times[0] / 1000;
        this.params.end_time = this.advancedForm.times[1] / 1000;
      }
      delete this.params.times;
      this.GET_MemberList();
    },
    /** 提交群发短信表单 */
    submitMessageVisibleForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.btnAllFlag) {
            this[formName].sendall = "ALL";
            this.ids_list = [0];
          } else {
            this[formName].sendall = "";
          }
          if (formName === "ZhanneiMessageVisibleForm") {
            /** 发送站内信 */
            API_Sms.putDlyqf(
              this.ZhanneiMessageVisibleForm,
              this.ids_list
            ).then((res) => {
              this.dialogZhanneiMessageVisible = false;
              this.$message.success("发送成功");
              this.$refs[formName].resetFields();
            });
          } else {
            /** 群发短信 */
            // console.log(this.MessageVisibleForm);
            /** 群发短信 */
            // console.log(this.MessageVisibleForm);
            // console.log(this.ids_list.join());
            API_Sms.addGroupmessage(
              this.MessageVisibleForm,
              this.ids_list.join()
            ).then((res) => {
              this.dialogMessageVisible = false;
              this.$message.success("发送成功");
              this.$refs[formName].resetFields();
            });
          }
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },

    /** 获取会员列表 */
    GET_MemberList () {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Member.getPageList(params)
        .then((response) => {
          this.loading = false;
          response.data = response.data.map(row => {
            row.nick_name = unescape(row.nick_name);
            return row;
          });
          this.tableData = response;
          // 判断是否全选
          if (this.btnAllFlag) {
            // 选中数据回显
            this.$nextTick(() => {
              this.$refs.multipleTable.selectCheckAll(this.btnAllFlag);
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.show-pwd {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}

::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}

div.toolbar-btns {
  display: contents;
}
</style>
