/**
 * 群发短信相关API
 */

import request from '@/utils/request'

/**
 * 群发站内信
 * @param params
 */
export function putDlyqf(params, ids) {
  return request({
    url: `/admin/shops/dlyqf/${ids}`,
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 查询主动短信记录表列表，用于列表查询
 * @param params
 */
export function getMessageDiytemplate(params) {
  return request({
    url: `admin/systems/messageDiytemplate/list`,
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 群发短信
 * @param params
 */
export function addGroupmessage(params, shop_ids) {
  return request({
    url: `/admin/shops/${shop_ids}/groupmessage`,
    method: 'put',
    loading: false,
    params
  })
}
